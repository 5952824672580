<template>
  <div>
    <my-header></my-header>
    <div>
      <el-card class="box-card">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <el-form
              v-if="ruleForm"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="ruleForm.sex">
                  <el-radio label="男"></el-radio>
                  <el-radio label="女"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="省市区"
                prop="receiverProvince"
                v-if="ruleForm.receiverProvince.length"
              >
                <el-cascader
                  v-model="ruleForm.receiverProvince"
                  :options="allAreaData"
                  @change="handleChange1"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="昵称" prop="nickName">
                <el-input v-model="ruleForm.nickName"></el-input>
              </el-form-item>
              <el-form-item label="简介" prop="aboutme">
                <el-input type="textarea" v-model="ruleForm.aboutme"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >提交</el-button
                >
                <el-button @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <!-- 更改密码 -->
          <el-tab-pane label="更改密码" name="second">
            <!-- <el-steps :active="active" finish-status="success">
              <el-step title="步骤 1"></el-step>
              <el-step title="步骤 2"></el-step>
              <el-step title="步骤 3"></el-step>
            </el-steps> -->
            <div class="Currentpassword">
              <div v-if="cc" class="cc">
                    <div class="mima">
                      验证码修改密码
                    </div>
                    <div class="login-box-input-username">
                  <span>手机号</span>
                  <div >
                    <el-input
                      v-model="phonelogin.phone"
                      @input="changes()"
                      placeholder="请输入手机号"
                      :disabled="true"
                    ></el-input>
                  </div>
                </div>
                <div class="right">
                  <span class="login-box-input-password-code">验证码</span>
                  <div>
                    <el-input
                      v-model="phonelogin.code"
                      @input="changes()"
                      placeholder="请输入验证码"
                    ></el-input>
                    <el-button v-if="countdown" class="find-password">{{timeText}}s后重试</el-button>
                    <el-button v-else class="find-password"  @click="getcode">获取验证码</el-button>
                  </div>
                </div>
                <div class="bottom">
                  <el-button class="finds-password" type="primary"  @click="getcodesss">使用验证码修改密码</el-button>
                </div>
              </div>
              <div class="A" v-else>
                <h3>请输入您的密码。</h3>
                <el-input
                  v-model="Currentpassword"
                  placeholder="请输入新密码"
                ></el-input>
                
                <el-button class="AA" type="primary" @click="clickCurrentpassword"
                  >验证密码</el-button
                >
              </div>
            </div>
            <!-- <div v-else-if="active == 1" class="Newpassword">
              <div>
                <h3>请输入您的新密码。</h3>
                <el-input
                  v-model="Newpassword"
                  placeholder="请输入新密码"
                ></el-input>
                <el-button type="primary" @click="clickNewpassword"
                  >提交</el-button
                >
              </div>
            </div>
            <div v-else-if="active == 2" class="Newpassword">
              <div>
                <div>
                  <span class="iconfont">&#xe61c;</span>
                  <span style="padding: 10px; line-height: 30px; color: #666"
                    >密码修改成功,请重新登录！</span
                  >
                </div>
                <el-button type="primary" @click="logback">重新登录</el-button>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane
            label="业务员申请"
            name="third"
            v-if="roleId != 2 && roleId != 5"
          >
            <div class="apply">
              <div>
                业务员申请
              </div>
              <div>
                <el-button type="primary" @click="submitAgent"
                  >申请成为平台业务员</el-button
                >
                <el-button type="primary" @click="$router.push('/CompanyJoin')"
                  >申请成为商家业务员</el-button
                >
              </div>
              <div v-if="MysubmitAgent">
                <div>
                  <span>创建时间:</span>
                  <span v-text="format(MysubmitAgent.createTime)"></span>
                </div>
                <div>
                  <span>更新时间:</span>
                  <span v-text="format(MysubmitAgent.updateTime)"></span>
                </div>
                <div>
                  <span>状态:</span>
                  <span
                    :style="{
                      color:
                        MysubmitAgent.state == '0'
                          ? '#999'
                          : MysubmitAgent.state == '1'
                          ? '#85ce61'
                          : MysubmitAgent.state == '2'
                          ? 'red'
                          : '#aaa',
                    }"
                    v-text="
                      MysubmitAgent.state == '0'
                        ? '未操作'
                        : MysubmitAgent.state == '1'
                        ? '已通过'
                        : MysubmitAgent.state == '2'
                        ? '已拒绝'
                        : '未知'
                    "
                  ></span>
                </div>
                <div v-if="MysubmitAgent.messge">
                  <span>失败后留言:</span>
                  <span v-text="MysubmitAgent.messge"></span>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 设计师申请
           -->
          <!-- <el-tab-pane
            label="设计师申请"
            name="fourth"
            v-if="roleId != 2 && roleId != 5"
          >
            <div class="apply">
              <div>
                <el-button type="primary" @click="submitDesign"
                  >申请成为设计师</el-button
                >
              </div>
              <div v-if="MysubmitDesign">
                <div>
                  <span>创建时间:</span>
                  <span v-text="format(MysubmitDesign.createTime)"></span>
                </div>
                <div>
                  <span>更新时间:</span>
                  <span v-text="format(MysubmitDesign.updateTime)"></span>
                </div>
                <div>
                  <span>状态:</span>
                  <span
                    :style="{
                      color:
                        MysubmitDesign.state == '0'
                          ? '#999'
                          : MysubmitDesign.state == '1'
                          ? '#85ce61'
                          : MysubmitDesign.state == '2'
                          ? 'red'
                          : '#aaa',
                    }"
                    v-text="
                      MysubmitDesign.state == '0'
                        ? '未操作'
                        : MysubmitDesign.state == '1'
                        ? '已通过'
                        : MysubmitDesign.state == '2'
                        ? '已拒绝'
                        : '未知'
                    "
                  ></span>
                </div>
                <div v-if="MysubmitDesign.messge">
                  <span>失败后留言:</span>
                  <span v-text="MysubmitDesign.messge"></span>
                </div>
              </div>
            </div>
          </el-tab-pane> -->
          <!-- 申请成为售后 -->
          <!-- <el-tab-pane
            label="申请成为售后"
            name="five"
            v-if="roleId != 2 && roleId != 5"
          >
            <div class="apply">
              <div>
                <el-button type="primary" @click="submitAfter"
                  >申请成为售后</el-button
                >
              </div>
              <div v-if="MysubmitAfter">
                <div>
                  <span>更新时间:</span>
                  <span v-text="format(MysubmitAfter.updateTime)"></span>
                </div>
                <div>
                  <span>状态:</span>
                  <span
                    :style="{
                      color:
                        MysubmitAfter.state == '0'
                          ? '#999'
                          : MysubmitAfter.state == '1'
                          ? '#85ce61'
                          : MysubmitAfter.state == '2'
                          ? 'red'
                          : '#aaa',
                    }"
                    v-text="
                      MysubmitAfter.state == '0'
                        ? '未操作'
                        : MysubmitAfter.state == '1'
                        ? '已通过'
                        : MysubmitAfter.state == '2'
                        ? '已拒绝'
                        : '未知'
                    "
                  ></span>
                </div>
                <div v-if="MysubmitAfter.messge">
                  <span>失败后留言:</span>
                  <span v-text="MysubmitAfter.messge"></span>
                </div>
              </div>
            </div>
          </el-tab-pane> -->
          <el-tab-pane label="实名认证" name="six">
            <el-form
              v-show="isIs"
              :model="form"
              :rules="rules2"
              ref="form"
              
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="身份证图片" prop="img">
                <el-upload 
                  class="upload-demo"
                  :action="uploadUrl"
                  :on-remove="logohandleRemove"
                  :on-success="logohandleSuccess"
                  :on-exceed="logohandleonExceed"
                  :file-list="fileList"
                  :limit="2"
                  name="upload_file"
                  list-type="picture"
                >
                  <el-button size="small" type="primary" v-if="reveal">点击上传</el-button>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <el-form-item label="真实姓名" prop="realName">
                <el-input v-model="form.realName"></el-input>
              </el-form-item>
              <el-form-item label="身份证号码" prop="idCard">
                <el-input v-model="form.idCard"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitid('form')"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
            <el-form
              v-show="forms" 
              :model="formss"
              ref="form"
              label-width="100px"
              class="demo-ruleForm">
              <el-form-item>
                <div>已实名认证</div>
              </el-form-item>
              <el-form-item label="真实姓名" prop="realName">
                <el-input v-model="formss.realName"></el-input>
              </el-form-item>
              <el-form-item label="身份证号码" prop="idCard">
                <el-input v-model="formss.idCard"></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="我的简历" name="five" class="Five">
            <el-form class="demo-ruleForm" v-for="(item,index) in cityList" :key="index">
             <div class="first1">
            <span>{{item.region[0] + '-' + item.region[1]+"--"}}<span :style="{color:'red'}">{{item.type | positionType}}厨师</span></span>
          </div>
          <div class="first1">
            <span>昵称:</span>
            <span>{{item.nickName}}</span>
          </div>
          <div class="first1">
            <span>手机号:</span>
            <span>{{item.jobHuntingPhone}}</span>
          </div>
          <div class="first1">
            <span>期望薪资:</span>
            <span>{{item.expectedSalary}}</span>
          </div>
          <div class="first1">
            <span>地区:</span>
            <span>{{item.region[0] + '-' + item.region[1] + '-' + item.region[2]}}</span>
          </div>
          <div class="first11">
            <el-button type="primary" plain @click="modification(item.jobHuntingId)">修改</el-button>
            <el-button plain @click="Delete(item.jobHuntingId)">删除</el-button>
          </div>
            
            </el-form>
            <el-form
              v-show="isShow"
              :model="resume"
              :rules="rules3"
              ref="resume"
              :style="{ marginTop: '20px' }"
              label-width="100px"
            >
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="resume.phone" maxlength="11" placeholder="请输入手机号"></el-input>
                
              </el-form-item>
              <el-form-item
                label="省市区"
                prop="receiverProvince"
                v-if="resume.receiverProvince.length"
              >
                <el-cascader
                  v-model="resume.receiverProvince"
                  :options="allAreaData"
                  @change="handleChange1"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="期望薪资" prop="expectedSalary">
                <el-input v-model="resume.expectedSalary" placeholder="期望薪资"></el-input>
              </el-form-item>
              <el-form-item label="菜系" prop="type">
                <el-select v-model="resume.type" placeholder="请选择">
                    <el-option
                      v-for="item in position"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      @click.native="change(resume.type)">
                    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="简介" prop="aboutme">
                <el-input type="textarea" v-model="resume.aboutme" placeholder="请输入说明"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="add('resume')"
                  >添加简历</el-button>
               </el-form-item>
            </el-form>
          </el-tab-pane>
          <!-- 更改密码 -->
          <el-tab-pane label="解绑用户" name="seven">
            <div class="Currentpassword">
              <div v-if="bb" class="cc">
                    <div class="mima">
                      解绑用户手机
                    </div>
                    <div class="login-box-input-username">
                  <span>手机号</span>
                  <div >
                    <el-input
                      v-model="phonelogin.phone"
                      @input="changes()"
                      placeholder="请输入手机号"
                      :disabled="true"
                    ></el-input>
                  </div>
                </div>
                <div class="right">
                  <span class="login-box-input-password-code">验证码</span>
                  <div>
                    <el-input
                      v-model="phonelogin.code"
                      @input="changes()"
                      placeholder="请输入验证码"
                    ></el-input>
                    <el-button v-if="countdown" class="find-password">{{timeText}}s后重试</el-button>
                    <el-button v-else class="find-password"  @click="getcode">获取验证码</el-button>
                  </div>
                </div>
                <div class="bottom">
                  <el-button class="finds-password" type="primary"  @click="unbundlePhone">解绑手机</el-button>
                </div>
              </div>
              <div class="A" v-else>
                <!-- <h3>请输入您的密码。</h3>
                <el-input
                  v-model="Currentpassword"
                  placeholder="请输入新密码"
                ></el-input>
                
                <el-button class="AA" type="primary" @click="clickCurrentpassword"
                  >验证密码</el-button
                > -->
                <el-dialog title="改绑信息"
                  :fullscreen="true"
                  :visible.sync="dialogVisibleSpecifications"
                  class="message">
                  <el-form
                    :model="unbundle"
                    :rules="rules4"
                    ref="unbundle"
                    label-width="100px"
                    class="demo-ruleForm"
                  >
                    <el-form-item label="真实姓名" prop="realName">
                      <el-input v-model="unbundle.realName"></el-input>
                    </el-form-item>
                    <el-form-item label="新手机号" prop="phone">
                      <el-input
                      v-model="unbundle.phone"
                      @input="changes()"
                      placeholder="请输入新手机号"
                      
                    ></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="code">
          
                
                        <el-input
                          v-model="unbundle.code"
                          @input="changes()"
                          placeholder="请输入验证码"
                          
                        ></el-input>
                        <el-button v-if="countdowns" class="find-password">{{timeTexts}}s后重试</el-button>
                        <el-button v-else class="find-password"  @click="getcoded">获取验证码</el-button>
                    
                 
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                      <el-input v-model="unbundle.password"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证图片" prop="img">
                      <el-upload 
                        class="upload-demo"
                        :action="uploadUrl"
                        :on-remove="logohandleRemove"
                        :on-success="logohandleSuccess"
                        :on-exceed="logohandleonExceed"
                        :file-list="fileList"
                        :limit="2"
                        name="upload_file"
                        list-type="picture"
                      >
                        <el-button size="small" type="primary" v-if="reveal">点击上传</el-button>
                      </el-upload>
                      <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                      </el-dialog>
                    </el-form-item>
                    <el-form-item label="身份证号码" prop="idCard">
                      <el-input v-model="unbundle.idCard"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="SubmitBind('unbundle')"
                        >提交改绑</el-button
                      >
                    </el-form-item>
                  </el-form>
                </el-dialog>
              </div>
            </div>
            <!-- <div v-else-if="active == 1" class="Newpassword">
              <div>
                <h3>请输入您的新密码。</h3>
                <el-input
                  v-model="Newpassword"
                  placeholder="请输入新密码"
                ></el-input>
                <el-button type="primary" @click="clickNewpassword"
                  >提交</el-button
                >
              </div>
            </div>
            <div v-else-if="active == 2" class="Newpassword">
              <div>
                <div>
                  <span class="iconfont">&#xe61c;</span>
                  <span style="padding: 10px; line-height: 30px; color: #666"
                    >密码修改成功,请重新登录！</span
                  >
                </div>
                <el-button type="primary" @click="logback">重新登录</el-button>
              </div>
            </div> -->
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
    <el-dialog
      title="查看详情"
      :fullscreen="true"
      :visible.sync="dialogVisibleSpecification"
      class="message"
    >
    <el-form
          v-if="resume"
          :model="resume"
          :rules="rules3"
          ref="resume"
          :style="{ marginTop: '30px' }"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="resume.phone" placeholder="请输入手机号"></el-input>
            
          </el-form-item>
          <el-form-item
            label="省市区"
            prop="receiverProvince"
            v-if="resume.receiverProvince.length"
          >
            <el-cascader
              v-model="resume.receiverProvince"
              :options="allAreaData"
              @change="handleChange1"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="期望薪资" prop="expectedSalary">
            <el-input v-model="resume.expectedSalary" placeholder="期望薪资"></el-input>
          </el-form-item>
          <el-form-item label="职位" prop="type">
                <el-select v-model="resume.type" placeholder="请选择">
                    <el-option
                      v-for="item in position"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      @click.native="change(resume.type)">
                    </el-option>
                </el-select>
              </el-form-item>
          <el-form-item label="简介" prop="aboutme">
            <el-input type="textarea" v-model="resume.aboutme" placeholder="请输入说明"></el-input>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" plain @click="modifications('resume')"
              >立即修改</el-button>
             </el-form-item>
        </el-form>
      <!-- <div class="goods-message" >
        <div class="goods-message1" >
           <h1>{{lists.projectName}}</h1>
        <div>
          <span>工种名称: </span>
          <span>{{lists.workName}}</span>
        </div>
        <div>
          <span>预计天数: </span>
          <span>{{lists.dayNum}}天</span>
        </div>
        <div>
          <span>工单状态: </span>
          <span>{{lists.state | state }}</span>
        </div>
        <div>
          <span>所需人数: </span>
          <span>{{lists.peopleNum}}</span>
        </div>
        <div>
          <span>报名人数: </span>
          <span>{{lists.applyNum}}</span>
        </div>
        <div>
          <span>联系电话: </span>
          <span>{{lists.linkPhone}}</span>
        </div>
        <div>
          <span>现场图: </span>
          <span>
            <img :src="lists.spotImg" >
          </span>
        </div>
        </div>
      </div> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    let _IDRe18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    let _IDre15 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    var idCard = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入身份证号码"));
      } else if (!_IDRe18.test(value) || !_IDre15.test(value)) {
        callback(new Error("请输入正确身份证号!"));
      } else {
        callback();
      }
    };
    return {
      fileList: [],
      // 文件上传路径
      uploadUrl: "http://www.jujiangmofang.cn/kitchenac/file/upload",
      // 已提交业务员申请列表
      MysubmitAgent: {},
      // 已提交设计师申请列表
      // MysubmitDesign: {},
      // 已提交成为售后申请列表
      // MysubmitAfter: {},
      // 新密码
      Newpassword: "",
      // 现在的密码
      Currentpassword: "",
      cc :true,
      bb:true,
      // 步骤条状态
      active: 0,
      // 选项卡选中状态
      activeName: "first",
      // 省市区
      allAreaData: [],
      phonelogin:{
        phone:"",
        code:"",
      },
      countdown:null,
      countdowns:null,
      timeText:60,
      timeTexts:60,
      countdownStartTime: 60, 
      countdownStartTimes: 60, 
      timer:"",
      //职位
      position: [{
          value: '1',
          label: '鲁菜'
        }, {
          value: '2',
          label: '川菜'
        }, {
          value: '3',
          label: '粤菜'
        }, {
          value: '4',
          label: '苏菜'
          }, {
          value: '5',
          label: '闽菜'
        }, {
          value: '6',
          label: '浙菜'
        }, {
          value: '7',
          label: '湘菜'
        }, {
          value: '8',
          label: '徽菜'
        }],
      // position:["鲁菜","川菜","粤菜","苏菜","闽菜","浙菜","湘菜","徽菜"],
      //简历列表
      cityList:[],
      userinfo: "",
      dialogImageUrl: "",
      dialogVisible: false,
      reveal:true,
      //修改页面
      dialogVisibleSpecification:false,
      dialogVisibleSpecifications:false,
      //无简历
      isShow:false,
      forms:false,
      isIs:true,
      //我的简历
      resume:{
        jobHuntingId:'',
        phone : '', //String 手机号
        // provinceId //int 省
        // cityId//int 市
        // countyId //int 县
        receiverProvince: [],//省市县
        expectedSalary:'',//String 期望薪资
        aboutme:'', //String 简介
        type:'',//String 类型1鲁 2川 3粤 4苏 5闽 6浙 7湘 8徽
      },
      // 实名认证
      form: {
        idCardImg: [],
        idCard: "",
        realName: "",
        img:""
      },
      formss: {
        idCard: "",
        realName: "",
      },
      //改绑信息
      unbundle:{
        phone:"",
        code:"",
        password:"",
        idCardImg: [],
        idCard: "",
        realName: "",
        img:""
      },
      // 个人信息
      ruleForm: {
        sex: "",
        receiverProvince: [],
        nickName: "",
        aboutme: "",
      },
      rules3: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        receiverProvince: [
          { required: true, message: "请选择省市区", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择职位", trigger: "change" },
        ],
        expectedSalary: [{ required: true, message: "请输入期望薪资", trigger: "blur" }],
        
      },
      rules4: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [
          { required: true, message: "请输入正确验证码", trigger: "blur" },
        ],
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        // img: [{ required: true, message: "请输入身份证正反面", trigger: "change" }],
        idCard: [{required: true, validator: idCard, trigger: "blur" }],
        
        
      },
      rules2: {
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        idCard: [{required: true, validator: idCard, trigger: "blur" }],
        // img:[{required: true, message: "请上传图片", trigger: "change" }]
      },
      rules: {
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        idCard: [{ validator: idCard, trigger: "blur" }],
        nickName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        receiverProvince: [
          { required: true, message: "请选择省市区", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        aboutme: [{ required: true, message: "请填写简介", trigger: "blur" }],
      },
    }
  },
  filters:{
   // 1鲁 2川 3粤 4苏 5闽 6浙 7湘 8徽
			positionType(val){
				if(val==1){
					return '鲁菜'
				}else if(val==2){
					return '川菜'
				}else if(val==3){
					return '粤菜'
				}else if(val==4){
					return '苏菜'
				}else if(val==5){
					return '闽菜'
				}else if(val==6){
					return '浙菜'
				}else if(val==7){
					return '湘菜'
				}else{
          return '徽菜'
        }
			},
		},
  // 权限信息
  computed: {
    roleId() {
      return sessionStorage.getItem("roleId");
    },
  },
  methods: {
    //我的简历
    async cityLists(){
      let id = sessionStorage.getItem('userId')
      const res = await this.$http({
          method: "GET",
          url: `/front/jobHuntingView/get?userId=${id}`,
        });
        console.log(res.data.data.list)
        if (res.status == 200) {
         this.cityList = res.data.data.list
         if(this.cityList.length == 0){
           this.isShow = true;
         }else{
           this.isShow = false;
         }
        }
    },
    //修改简历
    modification(id){
      this.resume.jobHuntingId = id
      this.dialogVisibleSpecification = true;
    },
    //倒计时
		countDown() {
        let nowTime = this.timeText - 1;
			if (nowTime >= 0 || nowTimes >= 0) {
				this.timeText = nowTime;
			  this.timer = setTimeout(this.countDown, 1000);
			} else {
				this.countdown = false;
				this.timeText = this.countdownStartTime;
				clearTimeout(this.timer);
			}
		},
    //改绑倒计时
    countDowns() {
        let nowTimes = this.timeTexts - 1;
			if (nowTimes >= 0) {
				this.timeTexts = nowTimes;
			  this.timer = setTimeout(this.countDown, 1000);
			} else {
				this.countdowns = false;
				this.timeTexts = this.countdownStartTimes;
				clearTimeout(this.timer);
			}
		},
    async modifications(){
      let resume = this.resume;
      const res = await this.$http({
          method: "post",
          url: `/front/jobHuntingView/update`,
          data:{
            jobHuntingId:resume.jobHuntingId,
            phone: resume.phone,
            provinceId: resume.receiverProvince[0],
            cityId: resume.receiverProvince[1],
            countyId: resume.receiverProvince[2],
            expectedSalary: resume.expectedSalary,
            aboutme: resume.aboutme,
            type: resume.type,
          },
        });
        if(res.status == 200){
          this.$message({
              type: 'success',
              message: '修改成功!'
            });
        }
        // location.reload();
        // this.$router.push({path:"/index"})
    },
    //删除简历
    Delete(id){
     this.$confirm('确定删除简历么?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(async() => {
          const res = await this.$http({
          method: "post",
          url: `/front/jobHuntingView/delete?jobHuntingId=${id}`,
        });
          if(res.data == 200){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
          location.reload();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    // 步骤条下一步
    next() {
      if (this.active < 3) this.active++;
    },
    // 文件上传超过个数限制
    logohandleonExceed() {
      this.$message({
        message: "无法继续上传,上传图片个数超出限制。",
        type: "error",
      });
      
    },
    // logo上传函数
    logohandleRemove(file, fileList) {
      console.log(file, fileList);
      this.form.idCardImg.forEach((v, k) => {
        if (v.img == `${file.response.data.url}${file.response.data.uri}`) {
          this.form.idCardImg.splice(k, 1);
        }
      });
      console.log(this.form);
      console.log(JSON.stringify(this.form.idCardImg));
      if(this.form.idCardImg.length != 2){
        this.reveal = true;
      }
    },
    logohandleSuccess(k, v, list) {
      // this.$message({
      //   message: "身份证上传成功",
      //   type: "success",
      // });
      this.form.idCardImg.push({ img: `${k.data.url}${k.data.uri}` });
      if(this.form.idCardImg.length == 2){
        this.reveal = false;
       this.logohandleonExceed()
       
      }
     
      console.log(this.form);
    },
    // 处理事件函数
    format(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate();
      return Y + M + D;
    },
    // 实名认证
    submitid(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let forms = this.forms
          const res = await this.$http({
            method: "post",
            url: `front/user/identityVerification`,
            data: {
              idCardImg: JSON.stringify(this.form.idCardImg),
              idCard: this.form.idCard,
              realName: this.form.realName,
            },
          });
          if (res.data.status == 200) {
              this.$message({
              message: "实名认证成功",
              type: "success",
            });
          }else if(this.form.idCardImg.length == 1){
          this.$message({
            message: "请上传身份证正反面",
        });
        }else{
          return false;
          }
        } 
      });
    },
    // 提交改绑
    SubmitBind(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let userId = JSON.parse(sessionStorage.getItem("userinfo"))
          const res = await this.$http({
            method: "post",
            url: `front/user/updateInformation`,
            data: {
              id :userId.id,//String id
              phone:this.unbundle.phone, //String 新手机号
              VerificationCode:this.unbundle.code, //String 验证码
              password:this.unbundle.password, //String 新密码
              idCardImg: JSON.stringify(this.form.idCardImg),
              idCard: this.unbundle.idCard,
              realName: this.unbundle.realName,
            },
          });
          if (res.data.status == 200) {
              this.$message({
              message: "改绑成功,请重新登陆",
              type: "success",
            });
            this.logOut();
            this.$router.push('/index')
          }else if(this.unbundle.idCardImg.length == 1){
          this.$message({
            message: "请上传身份证正反面",
        });
        }else{
          return false;
          }
        } 
      });
    },
    async aa(){
       const res = await this.$http({
            method: "get",
            url: `front/user/getUserByToken`,
          });
          if(res.data.data.ifcheck == 1){
            this.forms = true;
            this.isIs = false;
          }else{
            
            this.forms = false;
            this.isIs = true;
          }
     console.log(res.data.data.ifcheck)
    },
    //添加简历
    async add(resume){
      this.$refs[resume].validate(async (valid) => {
        
        if (valid) {
          
          let resume = this.resume;
          const res = await this.$http({
            method: "post",
            url: `front/jobHuntingView/insert`,
            data: {
              phone: resume.phone,
              provinceId: resume.receiverProvince[0],
              cityId: resume.receiverProvince[1],
              countyId: resume.receiverProvince[2],
              expectedSalary: resume.expectedSalary,
              aboutme: resume.aboutme,
              type: resume.type,
            },
          });
          console.log(res.data)
          if (res.status == 500) {
            return false;
          }
        } else {
          // this.$message({
          //     message: "添加简历成功",
          //     type: "success",
          //   });
            // location.reload();
            const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if (this.resume.phone == '' || this.resume.phone.length <= 10 || !reg.test(this.resume.phone)) {
				
					this.$message({
						message: "请填写正确的手机号！！",
						duration: 1000
					});
					return;
				} else {
          return false;
        }
        }
        
      });
      
      
    },
    // 修改基本信息
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.ruleForm);
          let ruleForm = this.ruleForm;
          const res = await this.$http({
            method: "post",
            url: `front/user/updateBasicInformation`,
            data: {
              sex: ruleForm.sex == "男" ? 1 : 0,
              provinceId: ruleForm.receiverProvince[0],
              cityId: ruleForm.receiverProvince[1],
              countyId: ruleForm.receiverProvince[2],
              nickName: ruleForm.nickName,
              aboutme: ruleForm.aboutme,
            },
          });
          if(res.status == 200){
          this.$message({
              type: 'success',
              message: '修改成功!,请重新登陆'
            });
        }
        this.$router.push({path:"/index"})
      
        
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleChange(value) {
      console.log(value);
    },
    // 查询所有地区
    async getallAreaData() {
      const AreaData = JSON.parse(sessionStorage.getItem("allAreaData"));
      if (!AreaData) {
        const res = await this.$http({
          method: "get",
          url: "front/area/allAreaData",
        });

        this.allAreaData = res.data.data;
        var bbb = JSON.parse(
          JSON.stringify(res.data.data).replace(/areaId/g, "value")
        );
        var bbb = JSON.parse(JSON.stringify(bbb).replace(/areaName/g, "label"));
        var bbb = JSON.parse(
          JSON.stringify(bbb).replace(/childList/g, "children")
        );
        var bbb = JSON.parse(
          JSON.stringify(bbb).replace(/\,"children"\:\[\]/g, "")
        );
        this.allAreaData = bbb;
      }
      this.allAreaData = AreaData;
    },
    async getUserId() {
      const res = await this.$http({
        method: "get",
        url: "front/user/getUserByToken",
      });
      if (res.data.data) {
        sessionStorage.setItem("userId", res.data.data.id);
        this.ruleForm.sex = res.data.data.sex == 1 ? "男" : "女";
        const data = JSON.parse(sessionStorage.getItem("allAreaData"));
        let provinceData = {};
        let cityData = {};
        let districtData = {};
        data.forEach((item, index) => {
          if (item.label == res.data.data.region[0]) {
            provinceData = item;
          }
        });
        provinceData.children.forEach((item, index) => {
          if (item.label == res.data.data.region[1]) {
            cityData = item;
          }
        });
        cityData.children.forEach((item, index) => {
          if (item.label == res.data.data.region[2]) {
            districtData = item;
          }
        });
        this.ruleForm.receiverProvince.push(provinceData.value);
        this.ruleForm.receiverProvince.push(cityData.value);
        this.ruleForm.receiverProvince.push(districtData.value);
        this.resume.receiverProvince.push(provinceData.value);
        this.resume.receiverProvince.push(cityData.value);
        this.resume.receiverProvince.push(districtData.value);
        this.ruleForm.nickName = res.data.data.nickName;
        this.ruleForm.aboutme = res.data.data.aboutme;
        this.userinfo = res.data.data;
      }
    },
    // 获取该账户下已提交的业务员申请
    async getMysubmitAgent() {
      const res = await this.$http({
        method: "get",
        url: "front/checkAgent/getMyApplication",
      });
      if (res.data.status == 200) {
        console.log(res.data);
        this.MysubmitAgent = res.data.data;
      }
    },
    // 获取该账户下已提交的成为售后申请
    // async getMysubmitAfter() {
    //   const res = await this.$http({
    //     method: "get",
    //     url: "front/checkService/getMyApplication",
    //   });
    //   if (res.data.status == 200) {
    //     console.log(res.data);
    //     this.MysubmitAfter = res.data.data;
    //   }
    // },
    // 获取该账户下已提交的设计师申请
    // async getMysubmitDesign() {
    //   const res = await this.$http({
    //     method: "get",
    //     url: "front/checkDesign/getMyApplication",
    //   });
    //   if (res.data.status == 200) {
    //     console.log(res.data);
    //     this.MysubmitDesign = res.data.data;
    //   }
    // },
    // 提交业务员申请
   submitAgent() {
      this.$confirm('确定申请业务员吗?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(async() => {
          const res = await this.$http({
          method: "post",
          url: `front/checkAgent/submitApplication`,
        });
          if(res.data.status == 200){
            this.$message({
              type: 'success',
              message: '已发送申请,待审核!'
            });
          }
          this.reload();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消申请'
          });          
        });
      
    },
    // 提交设计师申请
    // async submitDesign() {
    //   const res = await this.$http({
    //     method: "post",
    //     url: "front/checkDesign/submitApplication",
    //   });
    //   if (res.data.status == 200) {
    //     this.$message({
    //       message: "提交成功,待审核！",
    //       type: "success",
    //     });
    //     this.reload();
    //   }
    //   console.log(res);
    // },
    // 提交成为售后申请
    // async submitAfter() {
    //   const res = await this.$http({
    //     method: "post",
    //     url: "front/checkService/submitApplication",
    //   });
    //   if (res.data.status == 200) {
    //     this.$message({
    //       message: "提交成功,待审核！",
    //       type: "success",
    //     });
    //     this.reload();
    //   }
    //   console.log(res);
    // },
    // 单击验证密码
    // async clickCurrentpassword() {
    //   console.log(this.Currentpassword);
    //   if (this.Currentpassword == "") {
    //     const h = this.$createElement;
    //     this.$message({
    //       message: h("p", null, [
    //         h("span", null, "请输入您的 "),
    //         h("i", { style: "color: teal" }, "当前密码"),
    //       ]),
    //     });
    //   } else {
    //     const res = await this.$http({
    //       method: "post",
    //       url: `front/user/webLogin`,
    //       data: {
    //         phone: this.userinfo.phone,
    //         password: this.Currentpassword,
    //       },
    //     });
    //     console.log(res.data.status);
    //     if (res.data.status == 200) {
    //       this.active = 1;
    //     } else {
    //       this.$message.error("当前密码输入错误，请重试。");
    //     }
    //   }
    // },
    // 退出登录
    async logOut() {
      const res = await this.$http({
        method: "post",
        url: "front/user/logout",
      });
      console.log(res);
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userId");
      this.userinfo = "";
    },
    // 单击提交新密码
    async clickCurrentpassword() {
      if (this.Currentpassword == "") {
        const h = this.$createElement;
        this.$message({
          message: h("p", null, [
            h("span", null, "请输入您的 "),
            h("i", { style: "color: teal" }, "新密码"),
          ]),
        });
      } else {
        const res = await this.$http({
          method: "post",
          url: `front/user/updatePassword`,
          data: {
            password: this.Currentpassword,
          },
        });
        if (res.data.status == 200) {
          this.$message({
          message: "修改密码成功",
          type: "success",
        });
          this.active = 2;
          // 密码修改成功退出登录
          this.logOut();
          this.$message.error("请重新登录。");
          this.$router.push({path:"/index"})
          
        } else {
          this.$message.error("修改密码失败，请重试。");
        }
      }
    },
    logback() {
      this.$router.push("/login");
    },
    handleChange1(value) {
      console.log(value);
    },
    change(val){
      console.log(val)
      this.resume.type = val
    },
    changes() {
      this.$forceUpdate();
    },
    async getcode() {
      const res = await this.$http({
        method: "post",
        url: `front/user/getVerificationCode`,
        data: {
          phone: this.phonelogin.phone,
          verificationCode:this.phonelogin.verificationCode
        },
      });
      console.log(this.phonelogin.verificationCode)
       
      if(this.phonelogin.phone == ''){
        this.$message({
          message:'手机号不能为空',
          type:"error"
        });
      //   if (res.data.status == 200) {
      //   // this.getroleId();
      //   // sessionStorage.setItem("token", res.data.data);
      //   this.$message({
      //     message: "获取验证码成功",
      //     type: "success",
      //   });
      //   console.log(res)
      //   this.$store.commit("updateshowlogin", false);
       
			// 	// this.inputDisabled = true;
      // } else {
      //   this.$message.error("验证码错误！");
      // }
      } else{
        this.countDown(); //执行倒计时
				this.countdown = true;
      }
    },
    //新手机号验证码
    async getcoded() {
      const res = await this.$http({
        method: "post",
        url: `front/user/getVerificationCode`,
        data: {
          phone: this.unbundle.phone,
          verificationCode:this.unbundle.code
        },
      });
      console.log(this.unbundle.code)
       
      if(this.unbundle.phone == ''){
        this.$message({
          message:'手机号不能为空',
          type:"error"
        });
      } else{
        this.countDowns(); //执行倒计时
				this.countdowns = true;
      }
    },
     getcodesss(){
            const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (this.phonelogin.phone == '' || this.phonelogin.phone.length <= 10 || !reg.test(this.phonelogin.phone)) {
              this.$message({
                message: "请填写正确的手机号！！",
                duration: 1000
              });
              return;
            }else if(this.phonelogin.code == ''){
            this.$message({
              message:'验证码不能为空',
              type:"error"
            });
          }else{
            this.cc = false;
            return false;
          }
        this.$store.commit("updateshowlogin", false);
    },
   async unbundlePhone(){
     const res = await this.$http({
        method: "post",
        url: `front/user/checkCode`,
        data: {
          phone: this.phonelogin.phone,
          code:this.phonelogin.code
        },
      });
       if(res.data.status == 200){
         const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (this.phonelogin.phone == '' || this.phonelogin.phone.length <= 10 || !reg.test(this.phonelogin.phone)) {
              this.$message({
                message: "请填写正确的手机号！！",
                duration: 1000
              });
              return;
            }else if(this.phonelogin.code == ''){
            this.$message({
              message:'验证码不能为空',
              type:"error"
            });
          }else{
            this.bb = false;
            this.dialogVisibleSpecifications = true;
            return false;
          }
       }else{
         return false;
       }
    },
    ww(){
      let ww = [];
      ww = JSON.parse(sessionStorage.getItem("userinfo"))
      console.log(ww.phone)
      this.phonelogin.phone = ww.phone;
      this.formss.realName = ww.realName;
      this.formss.idCard = ww.idCard;
    },
  },
  created() {
    this.aa();
    this.ww();
    this.getallAreaData();
    this.getUserId();
    this.getMysubmitAgent();
    this.cityLists();
    this.submitForm();
    // this.getMysubmitDesign();
    // this.getMysubmitAfter();
  },
};
</script>
<style lang="scss" scoped>
.el-form-item{
  margin-bottom:17px !important;
}
.text {
  font-size: 14px;
}
.item {
  padding: 18px 0;
}
.box-card {
  margin: 0 auto;
  margin-top: 150px;
  width: 75%;
  height: 500px;
  min-width: 1000px;
}
.demo-ruleForm {
  width: 70%;
  margin: 0 auto;
}
.el-tab-pane {
  margin: 0 auto;
}
.el-steps--horizontal {
  width: 70%;
  margin: 0 auto;
}
.Currentpassword {
  width: 40%;
  margin: 0 auto;
  
}
.Currentpassword div{
  padding: 5px 0px;
}
.A{
  margin-top: 30px;
}
.A h3{
  font-size: 20px;
  margin-bottom: 10px;
}
.AA{
  margin-top: 20px;
}
.Currentpassword .mima{
  width: 100%;
  font-size: 20px;
  text-align: center;
}
.right .find-password{
  float: right;
  margin-top: 10px;
}
.bottom {
  display: inline-block;
  width: 100%;
}
.iconfont {
  font-size: 40px;
  color: #67c23a;
}
.apply {
  & > div:nth-child(2) {
    display: flex;
    justify-content:space-around;
    margin-top: 50px;
    .el-button {
      margin-right: 50px;
    }
  }
  & > div:nth-child(1){
    
    font-size: 20px;
    text-align: center;
  }
  & > div:nth-child(3) {
    margin-top: 10px;
    border: 1px solid #aaa;
    div:not(:last-child) {
      border-bottom: 1px dashed #aaa;
    }
    div {
      text-align: center;
      padding: 10px;
      span:nth-child(1) {
        display: inline-block;
        width: 40%;
        text-align: right;
      }
      span:nth-child(2) {
        display: inline-block;
        width: 55%;
        margin-left: 15px;
        text-align: left;
      }
    }
  }
}
.Five{
  width: 400px;
  background: #fff;
  border: 1px solid rgb(216, 214, 214);
  border-radius: 15px;
  padding: 10px;
  overflow: visible;
}
.first1{
  padding: 8px 0;
}
.first11{
 padding: 15px 0;
 width: 100%;
 & >button:nth-child(2){
  margin-left: 50%;
 }
}
.message{
  width: 51%;
  margin: 0 auto;
}
</style>